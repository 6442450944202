<template>
  <div class="page">
    <home-header activeIndex="2" pageTitle="Q&A"/>
    <div class="wrap">
      <div class="list">
        <div class="item" v-for="(item, idx) in qaList" :key="idx" @click="toDetail(item)">
          <img class="icon" :src="require('@/assets/home/qa.png')" alt="" />
          <div class="con">
            <div class="tit">{{item.qaQuestions}}</div>
            <div class="desc">{{item.qaAnswers}}</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <a-pagination v-model="page" @change="onChangePage"
        :total="total"
        :page-size="size"
        show-less-items />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import moment from 'moment'
import { getQA } from '@/api/notice';
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/footer";

export default {
  name: "Home",
  components: {
    HomeHeader,
    Footer,
  },
  data() {
    return {
      page: 1,
      size: 6,
      total: 0,
      qaList: []
    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format('YYYY-MM-DD')
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "/qaDetail",
        query: {
          item: item
        }
      });
    },
    onChangePage(e) {
      this.getQA(e)
    },
    getQA(page) {
      getQA({
        condition:{
          xdType: localStorage.getItem("stage"),
        },
        page: page,
        size: this.size,
      }).then(res => {
        this.total = res.data.total
        this.qaList = res.data.rows
      })
    },
  },
  mounted() {
    this.getQA(this.page)
  },
}
</script>

<style lang="less" scoped>
.wrap{
  width: 1000px;
	height: 444px;
	background-color: #ffffff;
	box-shadow: 0px 4px 10px 0px 
		rgba(0, 0, 0, 0.1);
	border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
}
.list{
  min-height: 340px;
  .item{
    display: flex;
    align-items: center;
    padding: 0 32px;
    &:hover{
      background: #f6f6f6;
      cursor: pointer;
      .con{
        .tit{
          color: #35ab61;
        }
      }
    }
    .icon{
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    .con{
      border-bottom: solid 1px #dedede;
      padding: 18px 0;
      width: calc(100% - 48px);
      .tit{
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
      .desc{
        color: #999;
        font-size: 14px;
        padding-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.pagination{
  text-align: center;
  padding: 24px 0 32px;
  /deep/ .ant-pagination-item-active{
    background: #35ab61;
    a{
      color: #fff;
    }
  }
}
</style>