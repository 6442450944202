import { axios } from '@/utils/request'

// 获取通知公告
export function getNoticeList (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/notice/list',
    method: 'get',
    params: params
  })
}

// 获取民办学校选报温馨提示
export function getMbNotice (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/getMbrxNotice',
    method: 'get',
    params: params
  })
}

// 获取通知公告详情
export function getNoticeDetail (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/login/getNotice',
    method: 'get',
    params: params
  })
}

// 获取QA列表
export function getQA (params = {}) {
  return axios({
    url: '/fast-imagine/mobile/yw/QAList/page',
    method: 'post',
    data: params
  })
}
